<template>
	<component :is="is ?? 'article'" class="card">
		<header v-if="slots.header" class="card__header">
			<slot name="header" />
		</header>

		<div v-if="slots.image" class="card__img">
			<slot name="image" />
		</div>

		<div class="card__body">
			<slot v-if="slots.body" name="body" />
			<slot v-else />
		</div>

		<footer v-if="slots.footer" class="card__footer">
			<slot name="footer" />
		</footer>
	</component>
</template>

<script lang="ts" setup>
	const props = defineProps<{
		is?: any
	}>()

	const slots = useSlots()
</script>

<style lang="scss">
	@layer components {
		.card {
			display: flex;
			flex-direction: column;
			gap: 20px;

			&:is(a) {
				user-select: none;
			}

			&__header,
			&__img,
			&__footer {
				flex: 0 0 auto;
			}

			&__img {
				position: relative;
				overflow: hidden;
			}

			&__body {
				flex: 1 1 auto;
			}
		}
	}
</style>
